import {withNamespaces} from 'react-i18next'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {OnlineConferencingStatusOwnProps, OnlineConferencingStatusRuntimeProps} from './interfaces'
import {OnlineConferencingStatus as Presentation} from './status'

const mapRuntime = ({
  actions: {navigateToMainPage},
  state,
}: DetailsPageAppProps): OnlineConferencingStatusRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  navigateToMainPage,
})

export const OnlineConferencingStatus = connect<OnlineConferencingStatusOwnProps, OnlineConferencingStatusRuntimeProps>(
  mapRuntime,
)(withNamespaces()(Presentation))
