import * as React from 'react'

export const Plus = ({size}) => (
  <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 11 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <g transform="translate(-114.000000, -16.000000)">
        <g transform="translate(114.000000, 16.000000)">
          <polygon points="0 5 11 5 11 6 0 6" />
          <rect
            transform="translate(5.500000, 5.500000) rotate(-90.000000) translate(-5.500000, -5.500000) "
            x="0"
            y="5"
            width="11"
            height="1"
          />
        </g>
      </g>
    </g>
  </svg>
)
