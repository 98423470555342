import {getFormattedFullDate} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {BrowserOnly} from '../../../../commons/components/browser-only'
import * as s from './full-date.scss'
import {FullDateProps} from './index'

export const FullDate: React.FC<FullDateProps> = ({event, fullLocale}) => (
  <div data-hook="event-full-date" className={s.dateContainer}>
    <BrowserOnly>{getFormattedFullDate(event, fullLocale)}</BrowserOnly>
  </div>
)
