import {parseQueryParams, SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import * as React from 'react'

export const Conference = props => {
  const {conferencingToken} = props
  if (location) {
    const guestToken = parseQueryParams(location.href).guestToken

    return (
      <div style={{textAlign: 'center'}}>
        <iframe
          title={'Video Conferencing'}
          width={'100%'}
          height={700}
          scrolling="no"
          frameBorder="0"
          allow="autoplay *; camera *; microphone *; geolocation *; vr *"
          allowTransparency={true}
          allowFullScreen
          src={`${SERVER_BASE_URL}/html/eyes-on/${conferencingToken}?guestToken=${guestToken}`}
        />
      </div>
    )
  } else {
    return null
  }
}
