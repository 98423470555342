export interface ComponentTexts {
  guestsTitleText: string
  rsvpButtonText: string
  timeAndLocationTitleText: string
  aboutTitleText: string
  ticketsTitleText: string
  readMoreText: string
  readLessText: string
  shareTitleText: string
}

export enum Alignment {
  LEFT = 1,
  CENTER = 2,
  RIGHT = 3,
}

export enum ButtonStyle {
  HOLLOW = 1,
  HOLLOW_ROUNDED = 2,
  FULL = 3,
  FULL_ROUNDED = 4,
}

export enum ImagePosition {
  TOP_LEFT = 1,
  TOP_CENTER = 2,
  TOP_RIGHT = 3,
  CENTER_LEFT = 4,
  CENTER = 5,
  CENTER_RIGHT = 6,
  BOTTOM_LEFT = 7,
  BOTTOM_CENTER = 8,
  BOTTOM_RIGHT = 9,
}

export enum ImageRatio {
  '1:2' = 1,
  '1:1' = 2,
}

export type Texts =
  | 'rsvpButtonText'
  | 'timeAndLocationTitleText'
  | 'readMoreText'
  | 'readLessText'
  | 'aboutTitleText'
  | 'ticketsTitleText'
  | 'shareTitleText'
  | 'guestsTitleText'
  | 'scheduleTitleText'
