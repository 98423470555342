import * as React from 'react'

const KALTURA_ENDPOINT = '2720731.kaf.kaltura.com'

export const Conference = props => {
  const {conferencingToken} = props

  return (
    <div style={{textAlign: 'center'}}>
      <iframe
        title={'Video Conferencing'}
        width={'100%'}
        height={700}
        scrolling="no"
        frameBorder="0"
        allow="autoplay *; camera *; microphone *; geolocation *; vr *"
        allowTransparency={true}
        allowFullScreen
        src={`https://${KALTURA_ENDPOINT}/virtualEvent/launch?ks=${conferencingToken}`}
      />
    </div>
  )
}
