import {withNamespaces} from 'react-i18next'
import {isMobile} from '../../../commons/selectors/environment'
import {getMembers, getTotalGuests} from '../../selectors/event'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {MembersRuntimeProps} from './interfaces'
import {Members as MembersPresentation} from './members'

const mapRuntime = ({state, actions: {openMembersModal}}: DetailsPageAppProps): MembersRuntimeProps => ({
  event: state.event,
  totalGuests: getTotalGuests(state),
  members: getMembers(state),
  openMembersModal,
  mobile: isMobile(state),
})

export const Members = connect<{}, MembersRuntimeProps>(mapRuntime)(withNamespaces()(MembersPresentation))
export * from './interfaces'
