import {getEventDescription, getEventTitle, hasImage} from '@wix/wix-events-commons-statics'
import {isDescriptionVisible} from '../../../selectors/event'
import {getHeaderAlignment, isSquareImage} from '../../../selectors/settings'
import {Alignment} from '../../../types'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Header as HeaderPresentation} from './header'

export interface HeaderProps extends HeaderRuntimeProps, HeaderOwnProps {}

export interface HeaderOwnProps {
  onClickRegistrationButton: Function
}

export interface HeaderRuntimeProps {
  title: string
  description: string
  descriptionVisible: boolean
  headerAlignment: Alignment
  isSquareImage: boolean
  imageVisible: boolean
}

const mapRuntime = ({state}: DetailsPageAppProps): HeaderRuntimeProps => ({
  title: getEventTitle(state.event),
  description: getEventDescription(state.event),
  descriptionVisible: isDescriptionVisible(state),
  headerAlignment: getHeaderAlignment(state.component.settings),
  isSquareImage: isSquareImage(state.component.settings),
  imageVisible: hasImage(state.event),
})

export const Header = connect<HeaderOwnProps, HeaderRuntimeProps>(mapRuntime)(HeaderPresentation)
