import {PlanList} from '@wix/wix-events-commons-statics/dist/types/exported-types'
import * as classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../../constants/data-hooks'
import {SelectBase} from '../../form/commons/select/select-base'
import * as fs from '../../form/form.scss'
import {MembershipSelectorProps} from './interfaces'
import {MembershipSelectorInput} from './membership-selector-input'
import {MembershipSelectorOptions} from './membership-selector-options'
import * as s from './membership-selector.scss'

export class MembershipSelector extends React.Component<MembershipSelectorProps> {
  optionsContainerRef = React.createRef<HTMLDivElement>()

  getPlanOptions = (): PlanList => [undefined, ...this.props.purchasedPlans]

  setSelectedPlan = (planOrderId: string) => {
    const {eventId, reservationId, applyPlan} = this.props
    applyPlan(eventId, reservationId, planOrderId)
  }

  render() {
    const {t, shouldShow, orderCompleted, selectedPlan, isMobile} = this.props
    const planOptions = this.getPlanOptions()
    const planIds = planOptions.map(plan => plan?.planOrderId)

    if (!shouldShow || (orderCompleted && !selectedPlan)) {
      return null
    }

    return (
      <div data-hook={DH.MEMBERSHIP_SELECTOR} className={classNames(s.container, fs.summaryBlock)}>
        <SelectBase
          optionsContainerRef={this.optionsContainerRef}
          options={planIds}
          selectedValue={selectedPlan?.planOrderId}
          renderInput={inputProps => <MembershipSelectorInput readOnly={orderCompleted} inputProps={inputProps} />}
          renderOptions={optionsProps => (
            <MembershipSelectorOptions
              isMobile={isMobile}
              planOptions={planOptions}
              optionsProps={optionsProps}
              ref={this.optionsContainerRef}
              t={t}
            />
          )}
          onChange={this.setSelectedPlan}
        />
      </div>
    )
  }
}
