import {withNamespaces} from 'react-i18next'
import {isCheckoutButtonDisabled} from '../../../../selectors/event'
import {showPaidPlans} from '../../../../selectors/paid-plans'
import {getButtonStyle} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {mapRuntime as ticketsPickerMapRuntime} from '../../../event-details/tickets-picker'
import {connect} from '../../../runtime-context/connect'
import {MobileTicketsPickerRuntimeProps} from './interfaces'
import {MobileTicketsPicker as Presentation} from './mobile-tickets-picker'

export const mapRuntime = (appProps: DetailsPageAppProps): MobileTicketsPickerRuntimeProps => {
  const {
    state,
    actions: {resetCouponCode, selectTicket, openCheckoutUnavailable, checkout, clearCheckout},
  } = appProps
  const ticketPickerState = ticketsPickerMapRuntime(appProps)
  const componentSettings = state.component.settings

  return {
    ...ticketPickerState,
    showPaidPlans: showPaidPlans(state),
    checkoutButtonDisabled: isCheckoutButtonDisabled(state),
    buttonStyle: getButtonStyle(componentSettings),
    resetCouponCode,
    selectTicket,
    openCheckoutUnavailable,
    checkout,
    clearCheckout,
  }
}

export const MobileTicketsPicker = connect<{}, MobileTicketsPickerRuntimeProps>(mapRuntime)(
  withNamespaces()(Presentation),
)

export * from './interfaces'
