import {getTimeZoneId} from '@wix/wix-events-commons-statics'
import {getButtonStyle, getText, isHollowButton} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {getFullLocale, isMobile} from '../../../../commons/selectors/environment'
import {getScheduleItems, getScheduleItemsTotal} from '../../../selectors/schedule'
import {Schedule as Presentation} from './schedule'
import {ScheduleRuntimeProps, ScheduleOwnProps} from './interfaces'

export const mapRuntime = ({state, actions: {navigateToSchedulePage}}: DetailsPageAppProps): ScheduleRuntimeProps => {
  const componentSettings = state.component.settings
  return {
    items: getScheduleItems(state),
    total: getScheduleItemsTotal(state),
    hollowButton: isHollowButton(getButtonStyle(componentSettings)),
    mobile: isMobile(state),
    timeZoneId: getTimeZoneId(state.event),
    locale: getFullLocale(state),
    scheduleTitleText: getText('scheduleTitleText', componentSettings),
    navigateToSchedulePage,
  }
}

export const Schedule = connect<ScheduleOwnProps, ScheduleRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
