import {getEventMainImage} from '@wix/wix-events-commons-statics'
import {withNamespaces} from 'react-i18next'
import {getImageOpacity, getImagePosition, getImageRatio} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {MobileImageOwnProps, MobileImageRuntimeProps} from './interfaces'
import {MobileImage as Presentation} from './mobile-image'

const mapRuntime = ({
  state: {
    event,
    component: {settings},
  },
}: DetailsPageAppProps): MobileImageRuntimeProps => ({
  mainImage: getEventMainImage(event),
  imageRatio: getImageRatio(settings),
  imagePosition: getImagePosition(settings),
  imageOpacity: getImageOpacity(settings),
})

export const MobileImage = connect<MobileImageOwnProps, MobileImageRuntimeProps>(mapRuntime)(
  withNamespaces()(Presentation),
)

export * from './interfaces'
