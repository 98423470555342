import {Alignment} from '../../../types'
import * as s from './alignment.scss'

export const getAlignmentClass = (alignment: Alignment) => {
  switch (alignment) {
    case Alignment.LEFT:
      return s.left
    case Alignment.RIGHT:
      return s.right
    case Alignment.CENTER:
    default:
      return s.center
  }
}
