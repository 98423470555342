import {isMobile} from '../../../commons/selectors/environment'
import {getConferencingToken} from '../../selectors/navigation'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Conference as Presentation} from './chime'

export interface ChimeRuntimeProps {
  conferencingToken: string
  mobile: boolean
}

const mapRuntimeToProps = ({state}: DetailsPageAppProps): ChimeRuntimeProps => ({
  conferencingToken: getConferencingToken(state),
  mobile: isMobile(state),
})

export const Chime = connect<{}, ChimeRuntimeProps>(mapRuntimeToProps)(Presentation)
