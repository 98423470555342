import * as classNames from 'classnames'
import * as React from 'react'
import {Minus} from '../../../../../icons/components/quantity-picker/minus'
import {Plus} from '../../../../../icons/components/quantity-picker/plus'
import {DH} from '../../../../constants/data-hooks'
import * as c from '../../../classnames.scss'
import * as s from './quantity-picker.scss'
import {QuantityPickerProps} from '.'

export const QuantityPicker: React.FC<QuantityPickerProps> = ({
  quantity,
  onChange,
  max,
  min,
  disabled,
  t,
  role,
  isMobile,
}) => {
  const iconSize = isMobile ? 11 : 9

  const isMinDisabled = disabled || quantity <= min
  const isMaxDisabled = disabled || quantity >= max

  const getDisabledClassName = (isDisabled: boolean) =>
    isDisabled ? c.quantityPickerDisabledSign : c.quantityPickerEnabledSign

  const containerClassNames = classNames(c.quantityPickerBorder, s.quantityPicker, {[s.disabled]: disabled})
  const minusClassNames = classNames(s.button, getDisabledClassName(isMinDisabled))
  const plusClassNames = classNames(s.button, getDisabledClassName(isMaxDisabled))

  const plusHandler = () => !isMaxDisabled && onChange(quantity + 1)
  const minusHandler = () => !isMinDisabled && onChange(quantity - 1)

  return (
    <div className={containerClassNames} data-hook={DH.QUANTITY_PICKER} role={role}>
      <button
        className={minusClassNames}
        data-hook={DH.MINUS_BUTTON}
        aria-label={t('accessibility:a11y.decreaseQuantity')}
        onClick={minusHandler}
      >
        <Minus size={iconSize} />
      </button>
      <div
        className={s.counter}
        data-hook={DH.QUANTITY_VALUE}
        aria-label={t('accessibility:a11y.selectedQuantity', {quantity})}
        aria-live="assertive"
      >
        {quantity}
      </div>
      <button
        className={plusClassNames}
        data-hook={DH.PLUS_BUTTON}
        aria-label={t('accessibility:a11y.increaseQuantity')}
        onClick={plusHandler}
      >
        <Plus size={iconSize} />
      </button>
    </div>
  )
}
