import * as React from 'react'
import {RsvpStatus} from '@wix/wix-events-commons-statics'
import {FormStep, RSVP_STEPS} from '../../../constants/constants'
import {isStepCompleted, isStepVisible} from '../../../selectors/rsvp'
import {BuyerDetails} from '../../buyer-details'
import {Policies} from '../../policies'
import {RsvpFormContainerProps} from '.'

export class RsvpFormContainer extends React.Component<RsvpFormContainerProps> {
  getSteps = () => {
    const {rsvpDetails, agreedWithPolicies, hasPolicies, t, editRsvpStep} = this.props
    return RSVP_STEPS.filter(step => isStepVisible({step, hasPolicies})).map(step => ({
      key: step,
      title: step === FormStep.BuyerDetails ? this.getTitle() : t(`mobile.checkout.step.${step}`),
      completed: isStepCompleted({
        step,
        rsvpDetails,
        agreedWithPolicies,
      }),
      renderStep: this.renderStep(step),
      onEdit: editRsvpStep,
    }))
  }

  getTitle = () => {
    const {
      messages: {
        rsvp: {positiveMessages, negativeMessages, waitlistMessages},
      },
      response,
    } = this.props

    if (response === RsvpStatus.YES) {
      return positiveMessages.title
    } else if (response === RsvpStatus.WAITING) {
      return waitlistMessages.title
    } else {
      return negativeMessages.title
    }
  }

  renderStep = (step: string) => {
    const {t, submitRsvpStep} = this.props
    const props = {
      onSubmit: (data: any) => submitRsvpStep(data),
      t,
    }

    switch (step) {
      case FormStep.BuyerDetails:
        return (editing: boolean) => <BuyerDetails editing={editing} {...props} />
      case FormStep.Policies:
        return (editing: boolean) => <Policies editing={editing} {...props} />
      default:
        return () => null
    }
  }

  render() {
    return this.props.children(this.getSteps(), this.props.currentStep)
  }
}
