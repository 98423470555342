import {isDateTbd} from '@wix/wix-events-commons-statics'
import {withNamespaces} from 'react-i18next'
import {getFormMessages} from '../../../selectors/event'
import {getThankYouMessageState} from '../../../selectors/navigation'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {getFormButtonStyle} from '../../../selectors/settings'
import {isMember} from '../../../selectors/current-member-details'
import {getCalendarLinks} from '../../../selectors/calendar-links'
import {ThankYouMessagesRuntimeProps} from './interfaces'
import {ThankYouMessages as Presentation} from './thank-you-messages'

const mapRuntime = ({
  state,
  actions: {thankYouPageLoaded, navigateToMainPage},
}: DetailsPageAppProps): ThankYouMessagesRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  return {
    messages: getFormMessages(event, getThankYouMessageState(state)),
    formButtonStyle: getFormButtonStyle(componentSettings),
    dateTbd: isDateTbd(event),
    calendarLinks: getCalendarLinks(state),
    isMember: isMember(state),
    thankYouPageLoaded,
    navigateToMainPage,
  }
}

export const ThankYouMessages = connect<{}, ThankYouMessagesRuntimeProps>(mapRuntime)(withNamespaces()(Presentation))
