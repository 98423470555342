import {ICashierPaymentsApi, ICashierPaymentsProps} from '@wix/cashier-payments-widget/dist/src/cashier-payments.d'
import {CashierPaymentsWidgetLazy} from '@wix/cashier-payments-widget/lazy'
import {EVENTS_APP_ID, focusElement, parseQueryParams} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {hookToAttributeSelector} from '../../../../constants/data-hooks'
import {ReservationState} from '../../../../types'
import {PaymentWidgetProps, PaymentWidgetState} from './interfaces'

const PAYMENT_WIDGET_ID = 'payment-widget'

export class PaymentWidget extends React.Component<PaymentWidgetProps, PaymentWidgetState> {
  state = {
    selectedPaymentMethodId: null,
  }

  cashierApi: Partial<ICashierPaymentsApi> = {
    pay: () => Promise.resolve(),
    validate: () => Promise.resolve({isValid: false, invalidFields: []}),
    collapse: () => Promise.resolve(),
    expand: () => Promise.resolve(),
  }

  componentDidMount() {
    this.focus()
  }

  focus = () => focusElement(`#${PAYMENT_WIDGET_ID}`)

  pay = () => this.cashierApi.pay()

  validate = () => this.cashierApi.validate().then(({isValid}) => isValid)

  collapse = () => this.cashierApi.collapse()

  expand = () => this.cashierApi.expand()

  onPaymentMethodChanged = (selectedPaymentMethodId: string) => {
    const {openCantCompletePaymentModal, isPreview} = this.props
    this.setState({selectedPaymentMethodId})
    if (isPreview) {
      openCantCompletePaymentModal()
    }
  }

  onPaymentResult = (result: any) => {
    if (result.status === 'OK') {
      const {request} = parseQueryParams(this.props.order.ticketsPdf)
      this.props.navigateToOrder(this.props.reservationId, ReservationState.SUCCESS, {token: request})
    } else {
      focusElement(hookToAttributeSelector('pw-payment-error'))
    }
  }

  getCashierProps = (): ICashierPaymentsProps => {
    const {
      snapshotId,
      locale,
      isMobile,
      externalSubmitButton,
      isSite,
      visitorId,
      instanceId,
      msid,
      primaryLanguage,
      siteOwnerId,
    } = this.props
    return {
      configuration: {
        appId: EVENTS_APP_ID as any,
        msid,
        appInstanceId: instanceId,
        snapshotId,
        locale,
        visitorId,
        isPrimaryLanguage: primaryLanguage,
        siteOwnerId,
      },
      theme: 'accordion',
      deviceType: isMobile ? 'mobile' : 'desktop',
      externalSubmitButton: externalSubmitButton || !isSite,
      paymentMethodChanged: this.onPaymentMethodChanged,
      paymentResult: this.onPaymentResult,
      walletPaymentInNewTab: false,
      shouldApplySiteStyles: true,
      allowManualPayment: true,
      isSaveCCEnabled: true,
    }
  }

  render() {
    return (
      <div id={PAYMENT_WIDGET_ID} className="a11yOutline">
        <CashierPaymentsWidgetLazy
          ref={async paymentWidget => {
            if (paymentWidget) {
              this.cashierApi = await paymentWidget.getApi()
            }
          }}
          {...this.getCashierProps()}
        />
      </div>
    )
  }
}
