import {withNamespaces} from 'react-i18next'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {getButtonStyle, isHollowButton} from '../../../../../selectors/settings'
import {ticketDescriptionExpanded} from '../../../../../selectors/tickets-picker'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {TicketDescriptionOwnProps, TicketDescriptionRuntimeProps} from './interfaces'
import {TicketDescription as Presentation} from './ticket-description'

export const mapRuntime = (
  {state, actions: {expandDescription, collapseDescription}}: DetailsPageAppProps,
  {ticketDefId}: TicketDescriptionOwnProps,
): TicketDescriptionRuntimeProps => ({
  expanded: ticketDescriptionExpanded(state, ticketDefId),
  isMobile: isMobile(state),
  isHollowButton: isHollowButton(getButtonStyle(state.component.settings)),
  expandDescription,
  collapseDescription,
})

export const TicketDescription = connect<TicketDescriptionOwnProps, TicketDescriptionRuntimeProps>(mapRuntime)(
  withNamespaces()(Presentation),
)
