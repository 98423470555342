import {getFirstNameControlMeta, getLastNameControlMeta} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../../../constants/data-hooks'
import {Error} from '../../commons/error'
import {Label} from '../../commons/label'
import * as fs from '../../fields.scss'
import * as c from '../../../classnames.scss'
import * as s from './name.scss'
import {NameProps} from '.'

export class Name extends React.Component<NameProps> {
  renderFirstName() {
    const {
      firstName: {
        input: {onChange, onBlur, value},
        meta: {touched, error},
      },
      control,
      disabled,
      t,
    } = this.props
    const {maxLength, label} = getFirstNameControlMeta(control)

    return (
      <div data-hook={touched && error ? DH.FORM_INVALID_FIELD : ''} className={s.wrapper}>
        <Label
          classes={classNames({[fs.disabledCaption]: disabled})}
          dataHook="first-name-caption"
          required
          htmlFor="firstName"
          caption={label}
        />
        <input
          className={classNames(c.formInputBorderColor, fs.input, {[c.formInputError]: touched && error})}
          data-hook="first-name-input"
          type="text"
          id="firstName"
          value={value}
          maxLength={maxLength}
          required
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
        />
        <Error dataHook="first-name-error" error={touched && error && t(error)} />
      </div>
    )
  }

  renderLastName() {
    const {
      lastName: {
        input: {onChange, onBlur, value},
        meta: {touched, error},
      },
      control,
      disabled,
      t,
    } = this.props
    const {maxLength, label} = getLastNameControlMeta(control)

    return (
      <div data-hook={touched && error ? DH.FORM_INVALID_FIELD : ''} className={s.wrapper}>
        <Label
          classes={classNames({[fs.disabledCaption]: disabled})}
          dataHook="last-name-caption"
          required
          htmlFor="lastName"
          caption={label}
        />
        <input
          className={classNames(c.formInputBorderColor, fs.input, {[c.formInputError]: touched && error})}
          data-hook="last-name-input"
          type="text"
          id="lastName"
          value={value}
          maxLength={maxLength}
          required
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
        />
        <Error dataHook="last-name-error" error={touched && error && t(error)} />
      </div>
    )
  }

  render() {
    return (
      <div className={classNames(fs.field, s.container)}>
        {this.renderFirstName()}
        {this.renderLastName()}
      </div>
    )
  }
}
