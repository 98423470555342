import {getAboutText} from '@wix/wix-events-commons-statics'
import {withNamespaces} from 'react-i18next'
import {isEditor, isMobile} from '../../../../commons/selectors/environment'
import {getButtonStyle, getTexts} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {showPaidPlans} from '../../../selectors/paid-plans'
import {AboutSection as Presentation} from './about-section'
import {AboutSectionOwnProps, AboutSectionRuntimeProps} from './interfaces'

export const mapRuntimeToProps = ({state}: DetailsPageAppProps): AboutSectionRuntimeProps => ({
  aboutTitleText: getTexts(state.component.settings).aboutTitleText,
  aboutText: getAboutText(state.event),
  readMoreText: getTexts(state.component.settings).readMoreText,
  readLessText: getTexts(state.component.settings).readLessText,
  buttonStyle: getButtonStyle(state.component.settings),
  isEditor: isEditor(state),
  isMobile: isMobile(state),
  showPaidPlans: showPaidPlans(state),
})

export const AboutSection = connect<AboutSectionOwnProps, AboutSectionRuntimeProps>(mapRuntimeToProps)(
  withNamespaces()(Presentation),
)
export * from './interfaces'
