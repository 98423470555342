import {isMobile} from '../../../commons/selectors/environment'
import {getConferencingToken} from '../../selectors/navigation'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Conference as Presentation} from './vonage'

export interface VonageRuntimeProps {
  conferencingToken: string
  mobile: boolean
}

const mapRuntimeToProps = ({state}: DetailsPageAppProps): VonageRuntimeProps => ({
  conferencingToken: getConferencingToken(state),
  mobile: isMobile(state),
})

export const Vonage = connect<{}, VonageRuntimeProps>(mapRuntimeToProps)(Presentation)
