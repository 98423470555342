import * as React from 'react'
import {DH} from '../../../constants/data-hooks'
import {FormButton} from '../../form-button'
import {PaymentProps} from './interfaces'
import {PaymentWidget} from './payment-widget'
import * as s from './payment.scss'

export const Payment: React.FC<PaymentProps> = ({
  paymentRef,
  editing,
  eventId,
  order,
  reservationId,
  buttonStyle,
  onSubmit,
  openCantCompletePaymentModal,
  t,
  isMobile,
  locale,
  isSite,
  isPreview,
  visitorId,
  msid,
  instanceId,
  siteOwnerId,
  navigateToOrder,
  primaryLanguage,
}) => (
  <div className={s.container}>
    <PaymentWidget
      locale={locale}
      ref={paymentRef}
      eventId={eventId}
      snapshotId={order?.snapshotId}
      reservationId={reservationId}
      isMobile={isMobile}
      isPreview={isPreview}
      openCantCompletePaymentModal={openCantCompletePaymentModal}
      navigateToOrder={navigateToOrder}
      isSite={isSite}
      visitorId={visitorId}
      msid={msid}
      instanceId={instanceId}
      primaryLanguage={primaryLanguage}
      siteOwnerId={siteOwnerId}
      externalSubmitButton
      order={order}
    />
    {editing && (
      <div className={s.button}>
        <FormButton
          dataHook={DH.PAYMENT_METHOD_SUBMIT}
          type="submit"
          text={isMobile ? t('mobile.checkout.next') : t('mobile.checkout.placeOrder')}
          buttonStyle={buttonStyle}
          onClick={() => onSubmit()}
        />
      </div>
    )}
  </div>
)
