import {getFullLocale} from '../../../../commons/selectors/environment'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {FullDate as FullDatePresentation} from './full-date'

export interface FullDateProps extends FullDateRuntimeProps {}

export interface FullDateRuntimeProps {
  event: ExtendedEvent
  fullLocale: string
}

const mapRuntime = ({state}: DetailsPageAppProps): FullDateRuntimeProps => ({
  event: state.event,
  fullLocale: getFullLocale(state),
})

export const FullDate = connect<{}, FullDateRuntimeProps>(mapRuntime)(FullDatePresentation)
