import {getFormattedMoney, getFormattedPrice} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import {TranslationFunction} from 'i18next'
import * as React from 'react'
import X from 'wix-ui-icons-common/X'
import {OrderedTicket} from '../../../types'
import {DH} from '../data-hooks'
import {InvoiceBreakdown} from '../../invoice-breakdown'
import {OrderSummaryProps} from './interfaces'
import * as s from './order-summary.scss'

export class OrderSummary extends React.PureComponent<OrderSummaryProps> {
  close = () => this.props.closeModal()

  render() {
    const {eventDate, eventLocation, eventTitle, tickets, t} = this.props

    return (
      <div className={s.container}>
        <button
          className={classNames(s.closeButtonX, 'a11yOutline')}
          data-hook={DH.CLOSE_BUTTON}
          aria-label={t('accessibility:a11y.close')}
          onClick={this.close}
        >
          <X className={s.iconX} />
        </button>
        <div className={s.title}>{t('mobile.couponForm.title')}</div>
        <div className={s.eventTitle}>{eventTitle}</div>
        <div className={s.secondaryText}>{eventDate}</div>
        <div className={s.secondaryText}>{eventLocation}</div>
        <Divider />
        <div>
          {tickets.map(ticket => (
            <Ticket key={ticket.id} ticket={ticket} t={t} />
          ))}
        </div>
        <InvoiceBreakdown t={t} divider={<Divider />} />
      </div>
    )
  }
}

const Ticket = ({ticket, t}: {ticket: OrderedTicket; t: TranslationFunction}) => {
  const totalPrice = getFormattedPrice(ticket.orderedCount * Number(ticket.price.amount), ticket.price.currency)
  const price = getFormattedMoney(ticket.price)
  const quantity = ticket.orderedCount

  return (
    <div className={s.ticketInfo}>
      <div className={s.ticketName}>{ticket.name}</div>
      <div className={classNames(s.secondaryText, s.smallText, s.ticketPrice)}>{t('mobile.thanks.price', {price})}</div>
      <div className={s.spreadRow}>
        <div className={classNames(s.secondaryText, s.smallText)}>{t('mobile.thanks.quantity', {quantity})}</div>
        <div>{totalPrice}</div>
      </div>
      <div className={s.divider} />
    </div>
  )
}

const Divider = () => <div className={s.divider} />
