import * as classNames from 'classnames'
import * as React from 'react'
import * as s from '../../fields.scss'
import {ErrorProps} from '.'

export const Error: React.FC<ErrorProps> = ({dataHook, className, error}) =>
  error ? (
    <div data-hook={dataHook} className={classNames(s.error, className)} role="alert" aria-live="assertive">
      {error}
    </div>
  ) : null
