import * as React from 'react'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {Container} from '../common/container'
import {Subtitle} from '../common/subtitle'
import {Title} from '../common/title'
import {OnlineConferencingStatusProps} from './interfaces'

export const OnlineConferencingStatus: React.FC<OnlineConferencingStatusProps> = ({
  t,
  buttonStyle,
  navigateToMainPage,
}) => (
  <Container dataHook="online-conferencing-disabled">
    <div style={{textAlign: 'center'}}>
      <Title text={t('onlineConferencing_disabled_title')} />
      <Subtitle lines={[t('onlineConferencing_disabled_subtitle')]} />
      <RegistrationButtonSkin
        text={t('onlineConferencing_disabled_buttonText')}
        buttonStyle={buttonStyle}
        onClick={() => navigateToMainPage()}
      />
    </div>
  </Container>
)
