import classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../../constants/data-hooks'
import {isHollowButton} from '../../../selectors/settings'
import * as cl from '../../classnames.scss'
import {Subtitle} from '../../subtitle'
import * as s from './about-section.scss'
import {AboutSectionProps} from './index'

export interface AboutSectionState {
  collapsed: boolean
  overflows: boolean
}

const WRAPPER_ID = 'events-details-page-about-wrapper'
const INNER_ID = 'events-details-page-about-inner'

export class AboutSection extends React.Component<AboutSectionProps, AboutSectionState> {
  contentRef
  focusableList = ['a', 'button', 'input', 'textarea', 'select']

  state = {
    collapsed: true,
    overflows: false,
  }

  render() {
    const {aboutTitleText, readMoreText, readLessText, buttonStyle, compact, isMobile} = this.props
    const {collapsed} = this.state
    const wrapperStyle = this.state.collapsed ? {maxHeight: '12em', marginBottom: this.state.overflows ? 0 : 42} : {}

    return (
      <div
        className={classNames(s.container, isMobile ? s.mobileContainer : '', cl.evTextFont, cl.evTextColor)}
        data-hook={DH.ABOUT_SECTION}
      >
        <Subtitle dataHook="about" text={aboutTitleText} compact={compact} />
        <div id={WRAPPER_ID} className={s.wrapper} style={wrapperStyle}>
          <div
            id={INNER_ID}
            data-hook={DH.ABOUT_SECTION_TEXT}
            ref={ref => (this.contentRef = ref)}
            className={isHollowButton(buttonStyle) ? cl.aboutHollowText : cl.aboutFullText}
            role="text"
          />
        </div>
        {this.state.overflows ? (
          <button
            data-hook={DH.ABOUT_SECTION_BUTTON}
            aria-controls={INNER_ID}
            aria-expanded={!collapsed}
            className={this.getButtonClasses()}
            onClick={this.toggle}
          >
            {collapsed ? readMoreText : readLessText}
          </button>
        ) : null}
      </div>
    )
  }

  componentDidUpdate(props: AboutSectionProps) {
    if (this.props.aboutText !== props.aboutText) {
      this.setOverflow()
    }
  }

  componentDidMount() {
    const {collapsed} = this.state
    this.appendAboutText(collapsed)
    this.setOverflow()
  }

  toggle = () => {
    const {collapsed} = this.state
    this.appendAboutText(!collapsed)
    this.setState({collapsed: !this.state.collapsed})
  }

  getButtonClasses = () => {
    const {buttonStyle, showPaidPlans} = this.props

    return classNames(s.readMore, {
      [cl.readMoreHollowTextColor]: isHollowButton(buttonStyle),
      [cl.readMoreTextColor]: !isHollowButton(buttonStyle),
      [s.beforeMembershipOffers]: showPaidPlans,
    })
  }

  setOverflow() {
    const wrapper = document.getElementById(WRAPPER_ID)
    const inner = document.getElementById(INNER_ID)
    const overflows = inner && wrapper && inner.clientHeight > wrapper.clientHeight
    this.setState({overflows, collapsed: true})
  }

  appendAboutText(collapsed) {
    collapsed ? this.modifyFocus(this.removeFocus) : this.modifyFocus(this.applyFocus)
  }

  modifyFocus(modify) {
    const aboutText = this.getAboutText()
    const el = document.createElement('div')
    el.innerHTML = `${aboutText}`
    this.contentRef.innerHTML = ''
    this.contentRef.appendChild(modify(el))
  }

  getAboutText() {
    const {isEditor, aboutText, t} = this.props
    return isEditor && !aboutText ? t('demoEvent_aboutSectionText') : aboutText
  }

  removeFocus = el => {
    this.focusableList.forEach(htmlTag => {
      el.querySelectorAll(htmlTag).forEach(focusable => (focusable.tabIndex = -1))
    })
    return el
  }

  applyFocus = el => {
    this.focusableList.forEach(htmlTag => {
      el.querySelectorAll(htmlTag).forEach(focusable => (focusable.tabIndex = 0))
    })
    return el
  }
}
