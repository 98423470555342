import {getEventTitle, getTaxConfig, getTimeZoneId, isAssignedTickets, isDateTbd} from '@wix/wix-events-commons-statics'
import {withNamespaces} from 'react-i18next'
import {DetailsPageAppProps} from '../../app/interfaces'
import {getCalendarLinks} from '../../../selectors/calendar-links'
import {connect} from '../../runtime-context/connect'
import {getFormButtonStyle} from '../../../selectors/settings'
import {ReservationState} from '../../../types'
import {OrderSuccessRuntimeProps} from './interfaces'
import {OrderSuccess as Presentation} from './order-success'

const mapRuntime = ({
  state,
  actions: {ticketedThankYouPageLoaded, navigateToMainPage},
}: DetailsPageAppProps): OrderSuccessRuntimeProps => {
  const {
    event,
    component: {settings},
  } = state
  const order = state.placedOrder.order
  return {
    order,
    eventTitle: getEventTitle(event),
    dateTbd: isDateTbd(event),
    timeZoneId: getTimeZoneId(event),
    formButtonStyle: getFormButtonStyle(settings),
    isAssignedTickets: isAssignedTickets(event),
    taxConfig: getTaxConfig(event),
    locale: state.environment.locale,
    navigateToMainPage,
    calendarLinks: getCalendarLinks(state),
    orderPending: state.navigation.reservationState === ReservationState.PENDING,
    ticketedThankYouPageLoaded,
  }
}

export const OrderSuccess = connect<{}, OrderSuccessRuntimeProps>(mapRuntime)(withNamespaces()(Presentation))
export * from './interfaces'
