import classNames from 'classnames'
import * as React from 'react'
import * as cl from '../../../classnames.scss'
import * as s from './subtitle.scss'
import {SubtitleProps} from '.'

export const Subtitle: React.FC<SubtitleProps> = ({lines, dataHook, isAddToCalendarHidden}) => (
  <div
    className={classNames(
      cl.evTextFont,
      cl.formLabelsColor,
      s.subtitle,
      isAddToCalendarHidden && s.addToCalendarHidden,
    )}
    data-hook={dataHook}
  >
    {lines.map(text => text && <div key={text}>{text}</div>)}
  </div>
)
