import {Component} from 'react'
import {AppLoadedProps} from '.'

export class AppLoaded extends Component<AppLoadedProps> {
  componentDidMount() {
    this.props.host.registerToComponentDidLayout(this.props.appLoaded)
  }

  render() {
    return null
  }
}
