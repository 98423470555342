import {ButtonStyle, ComponentSettings, ImageRatio, State, Texts} from '../types'
import * as c from '../components/classnames.scss'
import {isDemoEvent} from './event'
import {isMemberEventRsvpReady} from './member-rsvp'

export const isShortDateLocationVisible = (settings: ComponentSettings) => settings.shortDateLocationVisible

export const isDescriptionEnabled = (settings: ComponentSettings) => settings.descriptionVisible

export const getImagePosition = (settings: ComponentSettings) => settings.imagePosition

export const getImageRatio = (settings: ComponentSettings) => settings.imageRatio

export const getImageOpacity = (settings: ComponentSettings) => settings.imageOpacity

export const getButtonStyle = (settings: ComponentSettings) => settings.buttonStyle

export const isSquareImage = (settings: ComponentSettings) => settings.imageRatio === ImageRatio['1:1']

export const getContentAlignment = (settings: ComponentSettings) => settings.contentAlignment

export const getHeaderAlignment = (settings: ComponentSettings) => settings.headerAlignment

export const getFormButtonStyle = (settings: ComponentSettings) => settings.formButtonStyle

export const getTexts = (settings: ComponentSettings) => settings.texts

export const isSocialShareVisible = (settings: ComponentSettings) => settings.socialShareVisible

export const isScheduleVisible = (settings: ComponentSettings) => settings.scheduleVisible

export const isRsvpButtonEnabled = (settings: ComponentSettings) => settings.rsvpButtonVisible

export const isMembersAvatarsEnabled = (settings: ComponentSettings) => settings.membersVisible

export const isAboutSectionEnabled = (settings: ComponentSettings) => settings.aboutSectionVisible

export const isMapEnabled = (settings: ComponentSettings) => settings.mapVisible

export const isMembershipOffersVisible = (settings: ComponentSettings) => settings.membershipsVisible

export const isRsvpButtonVisible = (state: State) =>
  isRsvpButtonEnabled(state.component.settings) && (isDemoEvent(state) || isMemberEventRsvpReady(state))

export const isRoundedButton = (buttonStyle: ButtonStyle) =>
  [ButtonStyle.FULL_ROUNDED, ButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

export const isHollowButton = (buttonStyle: ButtonStyle) =>
  [ButtonStyle.HOLLOW, ButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

export const isFullButton = (buttonStyle: ButtonStyle) =>
  [ButtonStyle.FULL, ButtonStyle.FULL_ROUNDED].includes(buttonStyle)

export const getFormClassName = (buttonStyle: ButtonStyle) =>
  isHollowButton(buttonStyle) ? c.formWithHollowButton : c.formWithFullButton

export const getText = (key: Texts, settings: ComponentSettings) => settings.texts[key] || settings[key]
