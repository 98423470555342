export enum TAB {
  MAIN = 'main',
  MY_EVENTS = 'events',
  LAYOUT = 'layout',
  DISPLAY = 'display',
  TEXT = 'texts',
  DESIGN = 'design',
  PAGES = 'pages',
  SUPPORT = 'support',
  UPGRADE = 'upgrade',
  MEMBERS = 'members',
}

export enum PAGE {
  LANDING = 'landing',
}

export enum INNER_PAGE {
  DESIGN_TEXTS = 'design-texts',
  DESIGN_RIBBON = 'design-ribbon',
  DESIGN_BUTTONS = 'design-buttons',
  DESIGN_BACKGROUND = 'design-background',
  DESIGN_SPACES = 'design-spaces',
  DESIGN_IMAGE = 'design-image',
  DESIGN_HOVER = 'design-hover',
  DESIGN_CALENDAR_LIST_POPUP = 'design-calendar-list-popup',
  DESIGN_CALENDAR_DETAILS_POPUP = 'design-calendar-details-popup',

  IMAGE = 'image',
  TEXT_RSVP = 'text-rsvp',
  TEXT_RSVP_CLOSED = 'text-rsvp-closed',
  TEXT_TIME_AND_LOCATION = 'text-time-and-location',
  TEXT_TODAY = 'text-today',
}

export enum DETAILS_ROUTE {
  DETAILS = 'details',
  FORM = 'form',
  TICKET_FORM = 'ticket-form',
  CHECKOUT = 'checkout',
  PICK_TICKETS = 'pick-tickets',
  CHANGE_RSVP = 'change-rsvp',
  ORDER = 'order',
  ONLINE_CONFERENCING = 'oc',
  THANK_YOU_MESSAGES = 'thank-you-messages',
  LINK_EXPIRED = 'link-expired',
  NOT_FOUND = 'not-found',
}
