import {ExperimentsProvider} from '@wix/wix-experiments-react'
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'
import {TPAComponentsProvider} from 'wix-ui-tpa/dist/src/components/TPAComponentsConfig'
import * as classNames from 'classnames'
import {ErrorPage} from '../../../commons/components/error-page'
import {RuntimeContext} from '../../../commons/components/runtime-context/runtime-context'
import {Direction} from '../../../commons/constants/html'
import {isMobile, isMobileSize, isResponsive, isPreview} from '../../../commons/selectors/environment'
import {getExperiments} from '../../../commons/selectors/experiments'
import {ssrI18N} from '../../../commons/services/i18n'
import {ROOT_COMPONENT_ID} from '../../constants/constants'
import {AppLoaded} from '../app-loaded'
import {Modals} from '../modals'
import {Router} from '../router'
import {DetailsPageAppProps} from './interfaces'
import * as s from './app.scss'

export class App extends React.Component<DetailsPageAppProps> {
  componentDidUpdate(prevProps: DetailsPageAppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }

  render() {
    const {state, isRTL, host, translations} = this.props

    if (!state) {
      return <ErrorPage />
    }

    const experiments = getExperiments(state)
    const mobile = isMobile(state) || isMobileSize(state, host)
    const responsive = isResponsive(host)

    return (
      <RuntimeContext.Provider value={this.props}>
        <TPAComponentsProvider value={{mobile, rtl: isRTL}}>
          <I18nextProvider i18n={ssrI18N(translations)}>
            <ExperimentsProvider options={{experiments}}>
              <div dir={isRTL ? Direction.RTL : Direction.LTR} id={ROOT_COMPONENT_ID} className={s.root}>
                <Modals />
                <AppLoaded host={host} />
                <div
                  className={classNames(s.root, {
                    'events-details-page-mobile': mobile,
                    'events-details-page-desktop': !mobile,
                    'events-details-page-responsive': responsive,
                    'events-details-page-nonresponsive': !responsive,
                  })}
                >
                  <Router />
                </div>
              </div>
            </ExperimentsProvider>
          </I18nextProvider>
        </TPAComponentsProvider>
      </RuntimeContext.Provider>
    )
  }
}
