import * as React from 'react'
import * as classNames from 'classnames'
import Location from 'wix-ui-icons-common/Location'
import {Tags} from 'wix-ui-tpa/Tags'
import {ALIGNMENT, SIZE, SKIN} from 'wix-ui-tpa/dist/src/components/Tags/constants'
import {getDurationInMinutes, getTime, moment} from '@wix/wix-events-commons-statics'
import {Subtitle} from '../../subtitle'
import {TableRow} from '../../table-row'
import * as c from '../../classnames.scss'
import {ScheduleButton} from '../../styled-components/schedule-button'
import {DH} from '../../../constants/data-hooks'
import * as s from './schedule.scss'
import {ScheduleProps} from '.'

export const Schedule: React.FC<ScheduleProps> = ({
  hollowButton,
  t,
  mobile,
  items,
  total,
  timeZoneId,
  locale,
  navigateToSchedulePage,
  scheduleTitleText,
}) => {
  const itemsLeft = total - 2
  return (
    <div className={!mobile ? s.container : ''} data-hook={DH.SCHEDULE}>
      <Subtitle dataHook={DH.SCHEDULE_SUBTITLE} text={scheduleTitleText} />
      {items.slice(0, 2).map((item, index) => (
        <TableRow
          config={{
            itemKey: `scheduleItem-${index}`,
            addDivider: !mobile || index !== 1,
            className: mobile ? s.mobileRow : undefined,
            dividerClassName: classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor),
            columns: [
              {
                className: s.nameColumn,
                content: (
                  <div>
                    <span className={classNames(s.scheduleTime, c.scheduleTimeFont, c.scheduleTimeColor)}>
                      {`${getTime(item.timeSlot.start, timeZoneId, locale)} - ${getTime(
                        item.timeSlot.end,
                        timeZoneId,
                        locale,
                      )}`}
                    </span>
                    <br />
                    <span className={classNames(s.scheduleDuration, c.scheduleDurationFont, c.scheduleDurationColor)}>
                      {moment
                        .duration(
                          getDurationInMinutes(moment(item.timeSlot.start), moment(item.timeSlot.end)),
                          'minutes',
                        )
                        .humanize()}
                    </span>
                  </div>
                ),
              },
              {
                className: s.descriptionColumn,
                contentClassName: s.descriptionContent,
                content: (
                  <div>
                    <span className={classNames(s.scheduleTitle, c.scheduleTitleFont, c.scheduleTitleColor)}>
                      {item.name}
                    </span>
                    {item.stageName && (
                      <div className={classNames(s.location, c.scheduleLocationFont, c.scheduleLocationColor)}>
                        <Location /> {item.stageName}
                      </div>
                    )}
                    {item.tags && (
                      <Tags
                        className={s.tags}
                        alignment={ALIGNMENT.left}
                        skin={SKIN.light}
                        size={SIZE.small}
                        onClick={() => null}
                        items={item.tags?.map(tag => ({
                          title: tag,
                          value: tag,
                        }))}
                      />
                    )}
                  </div>
                ),
              },
            ],
          }}
        />
      ))}
      <div className={mobile ? s.mobileFooter : s.footer}>
        <div className={classNames(s.moreItemsText, c.scheduleLocationFont, c.scheduleLocationColor)}>
          {itemsLeft > 1
            ? t('schedule_moreItemsAvailable_plural', {amount: itemsLeft})
            : t('schedule_moreItemsAvailable_singular')}
        </div>
        <ScheduleButton mobile={mobile} t={t} hollowButton={hollowButton} onClick={() => navigateToSchedulePage()} />
      </div>
      {mobile && <div className={classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor)} />}
    </div>
  )
}
