import {getEventId} from '@wix/wix-events-commons-statics'
import {isEditor, isMobile} from '../../commons/selectors/environment'
import {openDialogModal} from '../../commons/services/modal'
import {getReservationId} from '../selectors/reservation'
import {GetState, StoreExtraArgs} from '../types'
import {navigateToDetails, navigateToTicketsPicker} from './navigation'
import {cancelReservation} from './reservation'

export const openCheckoutUnavailable = () => (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) =>
  dispatch(
    openDialogModal({
      type: 'checkout-unavailable',
      onClose: () => wixCodeApi.location.to(wixCodeApi.location.url),
    }),
  )

export const openTicketsDetailsModal = () => {
  return openNativeModal({modalType: 'order-summary'})
}

export const openTimeExpiredModal = () => (dispatch: Function, getState: GetState) => {
  if (isEditor(getState())) {
    return null
  }
  return dispatch(
    openDialogModal({
      type: 'time-expired',
      onClose: () => dispatch(onCloseTimeExpiredModal()),
    }),
  )
}

const onCloseTimeExpiredModal = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const eventId = getEventId(state.event)
  const reservationId = getReservationId(state)

  dispatch(cancelReservation(eventId, reservationId))
  if (isMobile(state)) {
    dispatch(navigateToTicketsPicker())
  } else {
    dispatch(navigateToDetails())
  }
}

export const openCantCompletePaymentModal = () =>
  openDialogModal({
    type: 'cant-complete-payment',
  })

export const OPEN_NATIVE_MODAL = 'OPEN_NATIVE_MODAL'

export const openNativeModal = ({modalType}) => ({
  type: OPEN_NATIVE_MODAL,
  payload: {
    modalType,
  },
})

export const CLOSE_NATIVE_MODAL = 'CLOSE_NATIVE_MODAL'

export const closeNativeModal = () => ({
  type: CLOSE_NATIVE_MODAL,
})
