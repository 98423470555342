import * as React from 'react'
import * as cn from '../../../classnames.scss'
import * as s from './container.scss'
import {ContainerProps} from '.'

export const Container: React.FC<ContainerProps> = ({children, dataHook}) => (
  <div className={cn.formBackgroundColor}>
    <main className={s.container} data-hook={dataHook}>
      {children}
    </main>
  </div>
)
