import {getTicketTax, isTaxAdded} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {DH} from '../../../../../constants/data-hooks'
import * as s from './tax.scss'
import {TaxProps} from '.'

export const Tax: React.FC<TaxProps> = ({visible, t, taxConfig, ticket}) => {
  if (!visible) {
    return null
  }

  const ticketTax = getTicketTax(ticket, taxConfig)

  return (
    <div className={s.tax} data-hook={DH.TAX_LABEL} aria-label={t('mobile.thanks.tax', {tax: `${ticketTax}`})}>
      {isTaxAdded(taxConfig.type) ? `+${ticketTax} ${taxConfig.name}` : t('tax.included', taxConfig)}
    </div>
  )
}
