import {withNamespaces} from 'react-i18next'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {LinkExpiredOwnProps, LinkExpiredRuntimeProps} from './interfaces'
import {LinkExpired as Presentation} from './link-expired'

const mapRuntime = ({actions: {navigateToMainPage}, state}: DetailsPageAppProps): LinkExpiredRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  navigateToMainPage,
})

export const LinkExpired = connect<LinkExpiredOwnProps, LinkExpiredRuntimeProps>(mapRuntime)(
  withNamespaces()(Presentation),
)
