import {getTicketServiceFee} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {DH} from '../../../../../constants/data-hooks'
import {isWixFeeTypeIsAdded} from '../../../../../selectors/tickets'
import * as s from './wix-fee.scss'
import {WixFeeProps} from '.'

export const WixFee: React.FC<WixFeeProps> = ({ticket, t, taxConfig}) => {
  if (!isWixFeeTypeIsAdded(ticket) || ticket.free) {
    return null
  }

  const fee = getTicketServiceFee(ticket, taxConfig)
  const label = t('checkout_plusServiceFee', {fee})

  return (
    <div className={s.wixFee} data-hook={DH.WIX_FEE} aria-label={label}>
      {label}
    </div>
  )
}
