import {getInputControlMeta} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {DH} from '../../../../constants/data-hooks'
import {Checkbox as CheckboxItem} from '../../commons/checkbox'
import {Error} from '../../commons/error'
import {Label} from '../../commons/label'
import * as fs from '../../fields.scss'
import * as s from './checkbox.scss'
import {CheckboxProps} from '.'

export class Checkbox extends React.Component<CheckboxProps> {
  toggleData = (value: string) => {
    const oldInputValue = this.getValues()
    const index = oldInputValue.indexOf(value)
    const newInputValue =
      index < 0 ? [...oldInputValue, value] : [...oldInputValue.slice(0, index), ...oldInputValue.slice(index + 1)]
    this.props.input.onChange(newInputValue)
  }

  getValues = () => this.props.input.value || []

  isSelected = (value: string) => this.getValues().includes(value)

  renderOption = (option: string, idx: number) => (
    <div className={s.option} key={idx}>
      <CheckboxItem value={option} checked={this.isSelected(option)} onChange={this.toggleData} />
    </div>
  )

  render() {
    const {
      meta: {dirty, touched, error},
      control,
      t,
    } = this.props
    const {mandatory, label, options} = getInputControlMeta(control)

    return (
      <div data-hook={(dirty || touched) && error ? DH.FORM_INVALID_FIELD : ''} className={fs.field}>
        <Label
          classes={fs.customCaption}
          dataHook="checkbox-caption"
          required={mandatory}
          caption={label}
          tabIndex={0}
        />
        <div>{options.map(this.renderOption)}</div>
        <Error dataHook="checkbox-error" error={(dirty || touched) && error && t(error)} />
      </div>
    )
  }
}
