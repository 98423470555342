import * as React from 'react'
import {DH} from '../../../constants/data-hooks'
import {RsvpInfoButton} from '../rsvp-info-button'
import {EditRegistrationProps} from '.'

export const EditRegistrationButton: React.FC<EditRegistrationProps> = ({
  t,
  buttonTextTranslation,
  messageTranslation,
  changeMemberRsvp,
}) => (
  <RsvpInfoButton
    messages={{
      exploreEventsActionLabel: t(buttonTextTranslation),
      message: t(messageTranslation),
    }}
    dataHook={DH.UPDATE_MEMBER_EVENT}
    onClick={changeMemberRsvp}
  />
)
