import {getEventId, hasCouponDiscount, isTicketed} from '@wix/wix-events-commons-statics'
import {connect} from '../../runtime-context/connect'
import {isCouponsAccepted} from '../../../selectors/checkout-options'
import {getInvoice, getCouponCode, getInvoiceError} from '../../../selectors/invoice'
import {isOrderCompleted} from '../../../selectors/placed-order'
import {getReservationId} from '../../../selectors/reservation'
import {getSortedSelectedTickets} from '../../../selectors/selected-tickets'
import {isPaidTicketSelected} from '../../../selectors/tickets'
import {DetailsPageAppProps} from '../../app/interfaces'
import {CouponInput as Presentation} from './coupon-input'
import {CouponInputOwnProps, CouponInputRuntimeProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {applyCoupon, setCouponCode, resetCouponCode},
}: DetailsPageAppProps): CouponInputRuntimeProps => {
  const placedOrder = state.placedOrder.order
  const event = state.event
  return {
    couponCode: getCouponCode(state) || '',
    eventId: getEventId(event),
    error: getInvoiceError(state),
    reservationId: getReservationId(state),
    discountValid: hasCouponDiscount(getInvoice(state)),
    orderCompleted: isOrderCompleted(placedOrder),
    showCouponInput:
      isPaidTicketSelected(state.tickets, getSortedSelectedTickets(state)) &&
      isTicketed(event) &&
      (!isOrderCompleted(placedOrder) || hasCouponDiscount(getInvoice(state))) &&
      isCouponsAccepted(state),
    applyCoupon,
    setCouponCode,
    resetCouponCode,
  }
}

export const CouponInput = connect<CouponInputOwnProps, CouponInputRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
