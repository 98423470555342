import {isMobile} from '../../../commons/selectors/environment'
import {getConferencingToken} from '../../selectors/navigation'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Conference as Presentation} from './eyes-on'

export interface EyesOnRuntimeProps {
  conferencingToken: string
  mobile: boolean
}

const mapRuntimeToProps = ({state}: DetailsPageAppProps): EyesOnRuntimeProps => ({
  conferencingToken: getConferencingToken(state),
  mobile: isMobile(state),
})

export const EyesOn = connect<{}, EyesOnRuntimeProps>(mapRuntimeToProps)(Presentation)
