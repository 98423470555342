import {getInputControlMeta, isShortAddressControl} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as _ from 'lodash'
import * as React from 'react'
import {DH} from '../../../../constants/data-hooks'
import {Error} from '../../commons/error'
import {Label} from '../../commons/label'
import * as fs from '../../fields.scss'
import * as c from '../../../classnames.scss'
import * as s from './address.scss'
import {AddressProps, AddressState} from '.'

export class Address extends React.Component<AddressProps, AddressState> {
  captionsOrderMap = {
    street: 1,
    city: 2,
    state: 3,
    country: 4,
    postal: 5,
  }

  state = {touched: []}

  getCurrentValue = () => this.props.input.value || []

  onChange(newValue: string, idx: number) {
    const value = _.clone(this.getCurrentValue())
    value[idx] = newValue
    this.props.input.onChange(value)
  }

  setTouched(idx: number) {
    const {touched} = this.state
    if (!touched[idx]) {
      touched[idx] = true
      this.setState({touched})
    }
  }

  isTouched(idx: number) {
    return this.state.touched[idx] || this.props.meta.touched
  }

  isInvalid(idx: number) {
    return this.isTouched(idx) && this.props.meta.error?.[idx]
  }

  renderField(label: string, idx: number = 0) {
    const {
      meta: {error},
      control,
      t,
    } = this.props

    const {mandatory, name, maxLength} = getInputControlMeta(control)
    const data = this.getCurrentValue()
    const id = `${name}-${idx}`
    const value = typeof data === 'string' ? data : data[idx] || ''
    const invalid = this.isInvalid(idx)

    return (
      <div data-hook={invalid ? DH.FORM_INVALID_FIELD : ''} className={s.field} key={idx}>
        <Label
          classes={fs.customCaption}
          dataHook={DH.FORM_ADDRESS_LABEL}
          required={mandatory}
          htmlFor={id}
          caption={label}
        />
        <input
          className={classNames(c.formInputBorderColor, fs.input, {[c.formInputError]: invalid})}
          data-hook={DH.FORM_ADDRESS_INPUT}
          type="text"
          id={id}
          value={value}
          maxLength={maxLength}
          required={mandatory}
          onBlur={() => this.setTouched(idx)}
          onChange={event => this.onChange(event.target.value, idx)}
        />
        <Error dataHook={DH.FORM_ADDRESS_ERROR} error={invalid && t(error[idx])} />
      </div>
    )
  }

  renderShortAddress() {
    const {label} = getInputControlMeta(this.props.control)

    return <div className={s.address}>{this.renderField(label)}</div>
  }

  renderFullAddress() {
    const {additionalLabels} = getInputControlMeta(this.props.control)

    return (
      <div className={s.address}>
        {this.getSortedCaptions(additionalLabels).map((caption, idx) =>
          this.renderField(additionalLabels[caption], idx),
        )}
      </div>
    )
  }

  render() {
    return isShortAddressControl(this.props.control) ? this.renderShortAddress() : this.renderFullAddress()
  }

  getSortedCaptions(captions) {
    return Object.keys(captions).sort(
      (first: string, second: string) => this.captionsOrderMap[first] - this.captionsOrderMap[second],
    )
  }
}
