import {getEventTitle, getFormattedFullDate, getFormattedLocation, getTaxConfig} from '@wix/wix-events-commons-statics'
import {withNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../commons/selectors/environment'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {getOrderedTickets} from '../../../selectors/placed-order'
import {getSortedSelectedTickets} from '../../../selectors/selected-tickets'
import {OrderSummaryRuntimeProps} from './interfaces'
import {OrderSummary as OrderSummaryPresentation} from './order-summary'

const mapRuntimeToProps = ({state, actions: {closeNativeModal}}: DetailsPageAppProps): OrderSummaryRuntimeProps => {
  const {event, tickets} = state

  return {
    eventTitle: getEventTitle(event),
    eventDate: getFormattedFullDate(event, getFullLocale(state)),
    eventLocation: getFormattedLocation(event),
    tickets: getOrderedTickets(getSortedSelectedTickets(state), tickets),
    taxConfig: getTaxConfig(event),
    closeModal: closeNativeModal,
  }
}

export const OrderSummary = connect<{}, OrderSummaryRuntimeProps>(mapRuntimeToProps)(
  withNamespaces(null, {wait: true})(OrderSummaryPresentation),
)
