import {focusElement} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {hookToAttributeSelector, DH} from '../../../constants/data-hooks'
import {AddToCalendar} from '../common/add-to-calendar'
import {Container} from '../common/container'
import {Subtitle} from '../common/subtitle'
import {Title} from '../common/title'
import {Desktop} from './desktop'
import {Mobile} from './mobile'
import * as s from './order-success.scss'
import {OrderSuccessProps} from './index'

export class OrderSuccess extends React.Component<OrderSuccessProps> {
  componentDidMount() {
    this.props.ticketedThankYouPageLoaded()
    focusElement(hookToAttributeSelector(DH.ORDER_SUCCESS_TITLE))
  }

  render() {
    const {
      dateTbd,
      eventTitle,
      formButtonStyle,
      calendarLinks,
      isAssignedTickets,
      t,
      order: {firstName, email},
    } = this.props
    const registrationCompleteMsg = isAssignedTickets
      ? 'ticketsThankYou.regCompleteWithAssignedTickets'
      : 'ticketsThankYou.regComplete'
    const ticketsSentMsg = isAssignedTickets
      ? 'ticketsThankYou.ticketSentWithAssignedTickets'
      : 'ticketsThankYou.ticketSent'
    const isAddToCalendarVisible = !dateTbd

    return (
      <Container dataHook={DH.ORDER_SUCCESS}>
        <div className={s.header}>
          <Title
            className={s.title}
            text={t('ticketsThankYou.thanks', {firstName})}
            dataHook={DH.ORDER_SUCCESS_TITLE}
          />
          <Subtitle
            lines={[t(registrationCompleteMsg, {eventTitle}), t(ticketsSentMsg, {email})]}
            dataHook={DH.ORDER_SUCCESS_SUBTITLE}
            isAddToCalendarHidden={!isAddToCalendarVisible}
          />
          <AddToCalendar
            className={s.addToCalendar}
            visible={isAddToCalendarVisible}
            buttonText={t('ticketsThankYou.addToCalendar')}
            formButtonStyle={formButtonStyle}
            calendarLinks={calendarLinks}
            t={t}
          />
        </div>
        <div className={s.desktop} data-hook={DH.DESKTOP}>
          <Desktop {...this.props} />
        </div>
        <div className={s.mobile} data-hook={DH.MOBILE}>
          <Mobile {...this.props} />
        </div>
      </Container>
    )
  }
}
