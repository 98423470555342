import {getYearMonthDay} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import classNames from 'classnames'
import {Divider} from '../../common/divider'
import {OrderSuccessActions} from '../actions'
import {OrderSuccessProps} from '../index'
import * as c from '../../../classnames.scss'
import {Invoice} from './invoice'
import * as s from './mobile.scss'

export const Mobile: React.FC<OrderSuccessProps> = ({
  order: {orderNumber, created, invoice},
  timeZoneId,
  t,
  locale,
  navigateToMainPage,
  orderPending,
}) => {
  const date = getYearMonthDay(created.toString(), timeZoneId, locale)
  return (
    <div className={classNames(c.formLabelsColor, c.evTextFont)}>
      <div className={s.header}>
        <div className={s.orderNumber}>{t('ticketsThankYou.orderNo', {orderNumber})}</div>
      </div>
      <Divider />
      <div className={s.placedOn}>{t('ticketsThankYou.placedOn', {date})}</div>
      <Divider />
      <Invoice t={t} invoice={invoice} />
      <Divider />
      <div className={s.actions}>
        <OrderSuccessActions orderPending={orderPending} t={t} />
      </div>
      <button className={s.navigate} onClick={() => navigateToMainPage()}>
        {t('backToSite')}
      </button>
    </div>
  )
}
