import * as React from 'react'

export const Minus = ({size}) => (
  <svg width={`${size}px`} height="1" viewBox="0 0 11 1" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <g transform="translate(-16.000000, -21.000000)">
        <polygon points="16 21 27 21 27 22 16 22" />
      </g>
    </g>
  </svg>
)
