import {SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import * as React from 'react'

export const Conference = props => {
  const {conferencingToken} = props
  return (
    <div style={{textAlign: 'center'}}>
      <iframe
        title={'Video Conferencing'}
        width={'100%'}
        height={700}
        scrolling="no"
        frameBorder="0"
        allow="autoplay *; camera *; microphone *; geolocation *; vr *"
        allowTransparency={true}
        allowFullScreen
        src={`${SERVER_BASE_URL}/html/amazon-chime/${conferencingToken}`}
      />
    </div>
  )
}
