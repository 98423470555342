import * as React from 'react'
import Download from 'wix-ui-icons-common/Download'
import Print from 'wix-ui-icons-common/Print'
import {DH} from '../../../../constants/data-hooks'
import {isHollowButton} from '../../../../selectors/settings'
import * as c from '../../../classnames.scss'
import * as s from './actions.scss'
import {OrderSuccessActionsProps} from './interfaces'

export const OrderSuccessActions: React.FC<OrderSuccessActionsProps> = ({
  orderPending,
  formButtonStyle,
  t,
  ticketsPdf,
  downloadTickets,
}) => {
  if (orderPending) {
    return null
  }

  const handleDownload = () => {
    window.open(ticketsPdf)
    downloadTickets()
  }

  return (
    <div className={`${s.container} ${isHollowButton(formButtonStyle) ? c.hollowActionColor : c.fullActionColor}`}>
      <button className={s.print} data-hook={DH.PRINT} onClick={handleDownload}>
        {t('ticketsThankYou.print')}
        <div className={s.icon}>
          <Print size="22px" />
        </div>
      </button>
      <button className={s.download} data-hook={DH.DOWNLOAD} onClick={handleDownload}>
        {t('ticketsThankYou.download')}
        <div className={s.icon}>
          <Download size="22px" />
        </div>
      </button>
    </div>
  )
}
