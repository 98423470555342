import {isMobile} from '../../../commons/selectors/environment'
import {getConferencingToken} from '../../selectors/navigation'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Conference as Presentation} from './kaltura'

export interface KalturaRuntimeProps {
  conferencingToken: string
  mobile: boolean
}

const mapRuntimeToProps = ({state}: DetailsPageAppProps): KalturaRuntimeProps => ({
  conferencingToken: getConferencingToken(state),
  mobile: isMobile(state),
})

export const Conference = connect<{}, KalturaRuntimeProps>(mapRuntimeToProps)(Presentation)
