import classNames from 'classnames'
import * as React from 'react'
import * as c from '../../../classnames.scss'
import {SelectInputProps, SelectOptionsProps, SelectProps} from './interfaces'
import {SelectBase} from './select-base'
import * as s from './select.scss'

export class Select extends React.Component<SelectProps> {
  optionsContainerRef = React.createRef<HTMLDivElement>()

  renderInput = ({containerProps: {expanded, ...containerProps}, valueProps}: SelectInputProps) => (
    <div
      className={classNames(s.select, c.formSelect, {
        [s.active]: expanded,
        [c.formSelectActive]: expanded,
      })}
      aria-label={this.props.ariaLabel}
      {...containerProps}
    >
      <div className={classNames(s.value, c.formLabelsFont, c.formInputColor)} {...valueProps}>
        {this.props.value}
      </div>
    </div>
  )

  renderOptions = ({containerProps, getOptionProps}: SelectOptionsProps) => (
    <div ref={this.optionsContainerRef} className={classNames(s.options, c.formSelectOptions)} {...containerProps}>
      {this.props.options.map((option, index) => {
        const {selected, key, ...optionProps} = getOptionProps(index)

        return (
          <div
            key={key}
            className={classNames(s.option, c.formSelectOption, c.formLabelsFont, c.formInputColor, {
              [c.formSelectOptionSelected]: selected,
            })}
            {...optionProps}
          >
            {option}
          </div>
        )
      })}
    </div>
  )

  render() {
    return (
      <div className={s.container}>
        <SelectBase
          optionsContainerRef={this.optionsContainerRef}
          options={this.props.options}
          selectedValue={this.props.value}
          renderInput={this.renderInput}
          renderOptions={this.renderOptions}
          onChange={this.props.onChange}
        />
      </div>
    )
  }
}
